import { useRecoilValue, useSetRecoilState } from 'recoil'

import { loaderStatus, profileState } from '../recoil/atoms'
import { useEffect, useState } from 'react'
import router, { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'

import { getUser } from '../services/profileHelper'
import useModalState from '../hooks/useModalState'
import { SUBSCRIPTION_TYPES, WELCOME_VIDEO_URL } from 'utils/consts'

import GeneratingModal from '@components/GeneratingModal'
import WhoIsTopicRankerFor from '@components/WhoIsTopicRankerFor'
import SomethingWrong from '@components/SomethingWrongModal'
import LimitReached from '@components/LimitReachedModal'
import WistiaPopup from '@components/WistiaPopup/WistiaPopup'
import TryOut from '@components/TryOut'
import Footer from '@components/Footer'
import LandingPageHeader from '@components/LandingPageHeader'
import LandingPageHero from '@components/LandingPageHero'
import LandingPageHead from '@components/LandingPageHead'
import Testimonials from '@components/Testimonials'
import FeaturedIn from '@components/FeaturedIn'

import dynamic from 'next/dynamic'

const DynamicHowItWorks = dynamic(() => import('@components/HowItWorks'), {
  loading: () => <p>Loading...</p>,
})

const DynamicPricing = dynamic(() => import('@components/Pricing'), {
  loading: () => <p>Loading...</p>,
})

export default function LandingPage() {
  const router = useRouter()
  const { status } = useSession()

  const setLoader = useSetRecoilState(loaderStatus)
  const setProfile = useSetRecoilState(profileState)

  const profile = useRecoilValue(profileState)
  const paidUser =
    profile?.subscriptionData?.activePlan !== SUBSCRIPTION_TYPES.FREE.id

  if (profile && !paidUser) {
    window.location.href = '/checkout'
    // router.push('/checkout')
  }

  const [openModal, setOpenModal] = useState(false)
  const handleOpen = () => setOpenModal(true)
  const handleClose = () => {
    setLoader(false)
    router.replace({})
    setOpenModal(false)
  }

  const [openSomethingWrongModal, setOpenSomethingWrongModal] = useState(false)
  const handleOpenSomethingWrongModal = () => setOpenSomethingWrongModal(true)
  const handleCloseSomethingWrongModal = () => {
    setLoader(false)
    router.replace({})
    setOpenSomethingWrongModal(false)
  }

  const [openLimitReachedModal, setOpenLimitReachedModal] = useState(false)
  const handleLimitReachedOpen = () => setOpenLimitReachedModal(true)
  const handleLimitReachedClose = () => {
    setLoader(false)
    router.replace({})
    setOpenLimitReachedModal(false)
  }

  const [isWistiaOpen, openWistia, closeWistia] = useModalState(false)

  useEffect(() => {
    const generating = router.query.generating
    const error = router.query.error
    const limit = router.query.limit
    if (generating) {
      handleOpen()
    } else if (error) {
      handleOpenSomethingWrongModal()
    } else if (limit) {
      handleLimitReachedOpen()
    }
  }, [router.query])

  const [selectedFeature, setSelectedFeature] = useState(0)

  //Need for external links to work
  useEffect(() => {
    const hash = window.location.hash
    if (hash.length > 0) {
      window.location.hash = ''
      window.location.hash = hash
    }
  }, [])

  useEffect(() => {
    const getActualProfile = async () => {
      if (status === 'authenticated') {
        const actualProfile = await getUser()
        if (actualProfile) {
          setProfile(actualProfile)
        }
      }
    }
    getActualProfile().catch(console.error)
  }, [])

  useEffect(() => {
    const { showDemo } = router.query
    if (showDemo) {
      openWistia()
    }
  }, [router.query.showDemo])

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <LandingPageHead title={'AI-Powered SERP Analysis Tool  | TopicRanker'} />

      <WistiaPopup
        title="TopicRanker Walkthrough"
        isOpen={isWistiaOpen}
        handleClose={closeWistia}
        src={WELCOME_VIDEO_URL}
      />

      <LimitReached
        openDialog={openLimitReachedModal}
        handleClose={handleLimitReachedClose}
      />

      <GeneratingModal openDialog={openModal} handleClose={handleClose} />

      <SomethingWrong
        openDialog={openSomethingWrongModal}
        handleClose={handleCloseSomethingWrongModal}
        somethingWrong={true}
      />

      <LandingPageHeader />

      <LandingPageHero openWistia={openWistia} />

      <DynamicHowItWorks
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
      />

      <DynamicPricing isLandingPage={true} />

      <Testimonials />

      <FeaturedIn />

      <WhoIsTopicRankerFor />

      <TryOut />

      <Footer
        landing={true}
        hasShapes={true}
        hasWave={true}
        setSelectedFeature={setSelectedFeature}
      />
    </div>
  )
}
